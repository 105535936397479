import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';

import { RedirectErrorDialog } from './redirect-error/redirect-error.dialog';

import { BuildOptionsService } from './build-options.service';
import { TokenService } from './tokens.service';

import { STORAGE_KEY } from '../common/constants';

import { environment } from '../../environments/environment';

import { Observable } from 'rxjs';

interface RestServiceOpts {
  logXhr: boolean;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class RestService {
  public deviceToken: string;
  private redirectErrorDialogRef: MatDialogRef<RedirectErrorDialog>;

  constructor(
    private readonly http: HttpClient,
    private readonly buildOptionsService: BuildOptionsService,
    private readonly router: Router,
    private readonly dialog: MatDialog,
    private readonly tokenService: TokenService,
    @Inject('RestServiceOpts') @Optional() private readonly opts: RestServiceOpts
  ) {
    this.deviceToken = localStorage.getItem(STORAGE_KEY.AUTH_DEVICE_TOKEN) || null;
  }

  protected generateLogs(type: string, url: string, dataSent?: any, dataReturned?: any): void {
    console.log(`%c ${type.toUpperCase()} API CALL TO`, 'background-color: #333; color: #98bccd;');
    console.log(url);
    console.log(`%c DATA SENT`, 'background-color: #333; color: #fac5c5;');
    console.table(dataSent);
    console.log(`%c DATA RETURNED`, 'background-color: #333; color: #f5f5f5;');
    console.table(dataReturned);
  }

  private generateHttpPromise(httpMethod: string, url: string, body = null): Promise<any> {
    let promise: Promise<any> = null;

    if (body) {
      promise = this.http[httpMethod](url, body, {
        headers: this.buildOptionsService.buildOptions()
      })
        .toPromise()
        .catch(err => this.handleError(err));
    } else {
      promise = this.http[httpMethod](url, {
        headers: this.buildOptionsService.buildOptions()
      })
        .toPromise()
        .catch(err => this.handleError(err));
    }

    if (!environment.production && this.opts && this.opts.logXhr) {
      promise.then(res => {
        this.generateLogs(httpMethod, url, body, res);
      });
    }

    promise.then(res => {
      if (res && res.data && res.data.redirectUrl) {
        this.router.navigate([res.data.redirectUrl]);

        if (!this.redirectErrorDialogRef) {
          this.redirectErrorDialogRef = this.dialog.open(RedirectErrorDialog, {
            data: res.data
          });

          this.redirectErrorDialogRef
            .afterClosed()
            .toPromise()
            .then(() => {
              this.redirectErrorDialogRef = null;
            });
        }
      }
    });

    return promise;
  }

  async post(url: string, body?: any): Promise<any> {
    await this.tokenService.checkTokens();

    if (this.tokenService.isTokenExpired) {
      this.router.navigate(['/login']);
      this.tokenService.isTokenExpired = false;
      return Promise.resolve();
    }

    return this.generateHttpPromise('post', url, body);
  }

  async get(url: string): Promise<any> {
    await this.tokenService.checkTokens();

    if (this.tokenService.isTokenExpired) {
      this.router.navigate(['/login']);
      this.tokenService.isTokenExpired = false;
      return Promise.resolve();
    }

    return this.generateHttpPromise('get', url);
  }

  resolve(url: string): Observable<any> {
    return this.http.get(url, {
      headers: this.buildOptionsService.buildOptions()
    });
  }

  async delete(url: string): Promise<any> {
    await this.tokenService.checkTokens();

    if (this.tokenService.isTokenExpired) {
      this.router.navigate(['/login']);
      this.tokenService.isTokenExpired = false;
      return Promise.resolve();
    }

    return this.generateHttpPromise('delete', url);
  }

  async put(url: string, body?: any): Promise<any> {
    await this.tokenService.checkTokens();

    if (this.tokenService.isTokenExpired) {
      this.router.navigate(['/login']);
      this.tokenService.isTokenExpired = false;
      return Promise.resolve();
    }

    return this.generateHttpPromise('put', url, body);
  }

  private handleError(serverError: any): void {
    try {
      console.log('Caught try', serverError);
    } catch (e) {
      console.log('Caught catch', e);
    }
  }
}
