import { Component, OnInit, Inject, Injectable, ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material';

@Component({
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data,
    private readonly snackbarRef: MatSnackBarRef<AlertComponent>
  ) {}

  handleClose(): void {
    this.snackbarRef.closeWithAction();
  }
}

@Injectable()
export class AlertService implements OnInit {
  constructor(private readonly snackbar: MatSnackBar) {}

  ngOnInit() {}

  open(message: string): void {
    this.snackbar.openFromComponent(AlertComponent, {
      data: {
        message
      },
      horizontalPosition: 'end',
      duration: 10000
    });
  }
}
