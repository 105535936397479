var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { RestService } from '../services/rest.service';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "./rest.service";
import * as i2 from "./auth.service";
import * as i3 from "@angular/router";
var DashTeamsService = /** @class */ (function () {
    function DashTeamsService(rest, auth, router) {
        this.rest = rest;
        this.auth = auth;
        this.router = router;
        this.teamSubject = new BehaviorSubject({});
        this.contactsSubject = new BehaviorSubject([]);
        this.userCustomerSubject = new BehaviorSubject({});
        this.loadingSubject = new BehaviorSubject(true);
        this.holdingSubject = new BehaviorSubject([]);
        this.viewTeamMemberAcres = new BehaviorSubject(null);
    }
    DashTeamsService.prototype.getTeam = function (year) {
        var _this = this;
        if (year === void 0) { year = 2020; }
        if (this.auth.loggedIn()) {
            var promise = this.rest.get(environment.apiURL + "/team?year=" + year);
            promise.then(function (res) {
                _this.teamSubject.next(res);
                _this.loadingSubject.next(false);
            });
            return promise;
        }
        else {
            return Promise.resolve();
        }
    };
    DashTeamsService.prototype.getManagedTeams = function () {
        var _this = this;
        if (this.auth.loggedIn()) {
            return this.rest.get(environment.apiURL + "/user/managed-teams").then(function (res) {
                _this.auth.setUserManagedTeams(res.data);
            });
        }
        else {
            return Promise.resolve();
        }
    };
    DashTeamsService.prototype.removeTeamInvite = function (teamID, email) {
        return this.rest.post(environment.apiURL + "/team/" + teamID + "/removeInvite", { email: email });
    };
    DashTeamsService.prototype.createNewTeam = function (team) {
        return this.rest.post(environment.apiURL + "/team", team);
    };
    DashTeamsService.prototype.editTeamMember = function (team, editedMember) {
        var _this = this;
        var members = team.members.map(function (member) {
            if (member.id === editedMember.id) {
                member.email = editedMember.email;
                member.fname = editedMember.fname;
                member.isAdmin = editedMember.isAdmin;
                member.lname = editedMember.lname;
            }
            return member;
        });
        this.teamSubject.next(__assign({}, team, { members: members }));
        this.rest.put(environment.apiURL + "/team/edit", editedMember).then(function () {
            _this.getTeam();
        });
    };
    DashTeamsService.prototype.transferTeamMemberInfo = function (fromTeamMemberID, toTeamMemberID, teamID) {
        return this.rest.put(environment.apiURL + "/user/" + fromTeamMemberID + "/moveto/" + toTeamMemberID + "/team/" + teamID, {});
    };
    DashTeamsService.prototype.suspendTeamMember = function (teamID, teamMemberID) {
        return this.rest.delete(environment.apiURL + "/team/" + teamID + "/member/" + teamMemberID);
    };
    DashTeamsService.prototype.moveTeamMemberToEscrow = function (team, teamMemberID) {
        // Apply local changes
        var members = team.members.map(function (el) {
            if (el.id === teamMemberID) {
                // Reassign values to what the server will do anyway
                el.fname = "Data from " + el.fname;
                // Update member holding
                el.holding = 1;
            }
            return el;
        });
        this.teamSubject.next(__assign({}, team, { members: members }));
        return this.rest.delete(environment.apiURL + "/team/" + team.id + "/member/" + teamMemberID + "/remove");
    };
    DashTeamsService.prototype.deleteTeamMember = function (teamMemberID) {
        return this.rest.delete(environment.apiURL + "/user/" + teamMemberID);
    };
    DashTeamsService.prototype.reinstateTeamMember = function (teamID, teamMemberID) {
        return this.rest.put(environment.apiURL + "/team/" + teamID + "/member/" + teamMemberID, {});
    };
    DashTeamsService.prototype.toggleTeamMemberAdmin = function (team, teamMemberID, isAdmin) {
        var teamID = team.id;
        var members = team.members.map(function (member) {
            if (member.id === teamMemberID) {
                member.isAdmin = isAdmin;
            }
            return member;
        });
        this.teamSubject.next(__assign({}, team, { members: members }));
        return this.rest.put(environment.apiURL + "/team/" + teamID + "/makeAdmin/" + teamMemberID + "/" + isAdmin, {});
    };
    DashTeamsService.prototype.sendTeamMemberInvite = function (teamID, email) {
        return this.rest.post(environment.apiURL + "/team/" + teamID + "/invite", {
            email: email
        });
    };
    DashTeamsService.prototype.deactivateUserAccount = function () {
        return this.rest.post(environment.apiURL + "/user/deactivate", {});
    };
    DashTeamsService.prototype.reactivateUserAccount = function () {
        return this.rest.post(environment.apiURL + "/user/reactivate", {});
    };
    DashTeamsService.prototype.toggleSharedTeamData = function (teamID, typeID) {
        return this.rest.put(environment.apiURL + "/team/" + teamID + "/type/" + typeID, {});
    };
    DashTeamsService.prototype.toggleTeamBilling = function (teamID) {
        return this.rest.put(environment.apiURL + "/team/" + teamID + "/billing", {});
    };
    DashTeamsService.prototype.getContacts = function () {
        var _this = this;
        if (this.auth.loggedIn()) {
            var promise = this.rest.get(environment.apiURL + "/contact");
            promise.then(function (res) {
                _this.contactsSubject.next(res);
            });
            return promise;
        }
        else {
            return Promise.resolve();
        }
    };
    DashTeamsService.prototype.getUserCustomerInfo = function () {
        var _this = this;
        if (this.auth.loggedIn()) {
            var promise = this.rest.get(environment.apiURL + "/user/info");
            promise.then(function (res) {
                _this.userCustomerSubject.next(res);
                if (res && res.status === 0) {
                    _this.router.navigate(['/dash/financials/deactivated']);
                }
                else if (!res) {
                    console.warn('getUserCustomerInfo is', typeof res);
                }
            });
            return promise;
        }
        else {
            return Promise.resolve();
        }
    };
    DashTeamsService.ngInjectableDef = i0.defineInjectable({ factory: function DashTeamsService_Factory() { return new DashTeamsService(i0.inject(i1.RestService), i0.inject(i2.AuthService), i0.inject(i3.Router)); }, token: DashTeamsService, providedIn: "root" });
    return DashTeamsService;
}());
export { DashTeamsService };
