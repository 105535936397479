import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { STORAGE_KEY } from '../common/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/material/dialog";
var AuthService = /** @class */ (function () {
    function AuthService(router, matDialog) {
        this.router = router;
        this.matDialog = matDialog;
        this.token = '';
        this.deviceToken = '';
        this.role = -1;
        this.refresh = '';
        this.expires = -1;
        this.user = null;
        this.browserSecurityError = false;
        this.tokenKey = STORAGE_KEY.AUTH_TOKEN;
        this.expiresKey = STORAGE_KEY.AUTH_EXPIRES;
        this.refreshKey = STORAGE_KEY.AUTH_REFRESH;
        this.userKey = STORAGE_KEY.AUTH_USER;
        this.roleKey = STORAGE_KEY.AUTH_ROLE;
        this.deviceTokenKey = STORAGE_KEY.AUTH_DEVICE_TOKEN;
    }
    /**
     * @description Utility validator that checks if a user has required state
     * @returns {boolean}
     */
    AuthService.prototype.loggedIn = function () {
        if (!this.browserSecurityError) {
            if (this.getAuthToken() && this.getAuthToken() !== '' && this.getDeviceToken() && this.getDeviceToken() !== '') {
                return true;
            }
            else {
                return false;
            }
        }
    };
    /**
     * Utility helper that logs a user into this service's state
     */
    AuthService.prototype.login = function (expiryTime, refreshToken, jwt, deviceToken, userInfo) {
        this.setExpires(expiryTime);
        this.setRefresh(refreshToken);
        this.setAuthToken(jwt);
        this.setDeviceToken(deviceToken);
        this.setUser(userInfo);
    };
    AuthService.prototype.setAuthToken = function (token) {
        localStorage.setItem(this.tokenKey, token);
        this.token = token;
    };
    AuthService.prototype.setRole = function (role) {
        localStorage.setItem(this.roleKey, String(role));
        this.role = role;
    };
    AuthService.prototype.getAuthToken = function () {
        try {
            if (localStorage.getItem(this.tokenKey) && localStorage.getItem(this.tokenKey) !== '') {
                this.setAuthToken(localStorage.getItem(this.tokenKey));
            }
            else {
                return '';
            }
        }
        catch (e) {
            this.browserSecurityError = true;
        }
        return localStorage.getItem(this.tokenKey);
    };
    AuthService.prototype.setRefresh = function (refresh) {
        localStorage.setItem(this.refreshKey, refresh);
        this.refresh = refresh;
    };
    AuthService.prototype.getRefresh = function () {
        if (!this.browserSecurityError) {
            if (localStorage.getItem(this.refreshKey) && localStorage.getItem(this.refreshKey) !== '') {
                this.setRefresh(localStorage.getItem(this.refreshKey));
            }
        }
        return this.refresh;
    };
    AuthService.prototype.setExpires = function (expires) {
        localStorage.setItem(this.expiresKey, String(expires));
        this.expires = expires;
    };
    AuthService.prototype.getExpires = function () {
        if (!this.browserSecurityError) {
            if (localStorage.getItem(this.expiresKey) && localStorage.getItem(this.expiresKey) !== '') {
                this.setExpires(Number(localStorage.getItem(this.expiresKey)));
            }
        }
        return this.expires;
    };
    AuthService.prototype.setUser = function (user) {
        localStorage.setItem(this.userKey, JSON.stringify(user));
        this.user = user;
    };
    AuthService.prototype.getUser = function () {
        if (!this.browserSecurityError) {
            if (localStorage.getItem(this.userKey)) {
                this.user = JSON.parse(localStorage.getItem(this.userKey));
            }
        }
        return this.user;
    };
    AuthService.prototype.hasFeature = function (feature) {
        return this.getUser().subscriptions.includes(feature);
    };
    AuthService.prototype.setDeviceToken = function (deviceToken) {
        localStorage.setItem(this.deviceTokenKey, deviceToken);
        this.deviceToken = deviceToken;
    };
    AuthService.prototype.getDeviceToken = function () {
        if (!this.browserSecurityError) {
            if (localStorage.getItem(this.deviceTokenKey)) {
                this.deviceToken = localStorage.getItem(this.deviceTokenKey);
            }
        }
        return this.deviceToken;
    };
    /**
     * Destroys the local session
     */
    AuthService.prototype.logout = function () {
        this.matDialog.closeAll();
        this.setAuthToken(null);
        this.setRefresh(null);
        this.setExpires(null);
        this.setUser(null);
        this.setRole(null);
        localStorage.removeItem(this.tokenKey);
        localStorage.removeItem(this.refreshKey);
        localStorage.removeItem(this.expiresKey);
        localStorage.removeItem(this.userKey);
        localStorage.removeItem(this.roleKey);
        localStorage.removeItem(STORAGE_KEY.MAP_FILTER_SETTINGS);
    };
    AuthService.prototype.setUserManagedTeams = function (teams) {
        var user = this.getUser();
        user.managedTeams = teams;
        this.setUser(user);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.Router), i0.inject(i2.MatDialog)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
