import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth.service";
var JWTService = /** @class */ (function () {
    function JWTService(auth) {
        this.auth = auth;
    }
    JWTService.prototype.checkAuthToken = function () {
        if (this.auth.getAuthToken()) {
            return this.auth.getAuthToken();
        }
        else {
            return '';
        }
    };
    JWTService.prototype.checkDeviceToken = function () {
        if (this.auth.getDeviceToken()) {
            return this.auth.getDeviceToken();
        }
        else {
            return '';
        }
    };
    JWTService.ngInjectableDef = i0.defineInjectable({ factory: function JWTService_Factory() { return new JWTService(i0.inject(i1.AuthService)); }, token: JWTService, providedIn: "root" });
    return JWTService;
}());
export { JWTService };
