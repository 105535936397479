import { HttpHeaders } from '@angular/common/http';
import { JWTService } from './jwt.service';
import { version } from '../../../package.json';
import * as i0 from "@angular/core";
import * as i1 from "./jwt.service";
var BuildOptionsService = /** @class */ (function () {
    function BuildOptionsService(jwt) {
        this.jwt = jwt;
    }
    BuildOptionsService.prototype.buildOptions = function () {
        return new HttpHeaders({
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.jwt.checkAuthToken(),
            Client: 'web',
            'Device-Token': this.jwt.checkDeviceToken(),
            'Version-Number': version
        });
    };
    BuildOptionsService.ngInjectableDef = i0.defineInjectable({ factory: function BuildOptionsService_Factory() { return new BuildOptionsService(i0.inject(i1.JWTService)); }, token: BuildOptionsService, providedIn: "root" });
    return BuildOptionsService;
}());
export { BuildOptionsService };
