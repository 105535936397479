import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuthService } from './auth.service';
import { BuildOptionsService } from './build-options.service';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  public isTokenExpired: boolean = false;

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly buildOptionsService: BuildOptionsService
  ) {}

  async checkTokens(): Promise<any> {
    if (this.auth.loggedIn()) {
      return new Promise(async resolve => {
        try {
          const expires = this.auth.getExpires();
          const seconds: number = Math.floor(new Date().getTime() / 1000);
          const refreshToken = this.auth.getRefresh();

          if (expires < seconds + 50) {
            const res: any = await this.http
              .post(
                `${environment.apiURL}/auth`,
                { refreshToken },
                {
                  headers: this.buildOptionsService.buildOptions()
                }
              )
              .toPromise()
              .catch(err => {
                console.log(err);
                this.isTokenExpired = true;
                this.auth.logout();
              });

            if (res && res.expires) {
              this.auth.login(res.expires, res.refreshToken, res.jwt, res.deviceToken, {
                id: res.id,
                role: res.role,
                fname: res.fname,
                lname: res.lname,
                managedTeams: res.managedTeams,
                userType: res.userType,
                subscriptions: res.subscriptions
              });

              resolve();
            } else {
              this.isTokenExpired = true;
              this.auth.logout();
              resolve();
            }
          } else {
            resolve();
          }
        } catch (err) {
          this.isTokenExpired = true;
          resolve(err);
        }
      });
    } else {
      return;
    }
  }
}
