import { STORAGE_KEY } from '../common/constants';
import * as i0 from "@angular/core";
var UiElementPreferencesService = /** @class */ (function () {
    function UiElementPreferencesService() {
        this.userNotManagerModalKey = STORAGE_KEY.USER_NOT_MANAGER;
        this._showUserNotManagerModal = true;
    }
    UiElementPreferencesService.prototype.setShowUserNotManagerModal = function (showModal) {
        localStorage.setItem(this.userNotManagerModalKey, showModal.toString());
        this._showUserNotManagerModal = showModal;
    };
    UiElementPreferencesService.prototype.showUserNotManagerModal = function () {
        if (localStorage.getItem(this.userNotManagerModalKey)) {
            var castedBoolean = localStorage.getItem(this.userNotManagerModalKey) === 'true' ? true : false;
            this.setShowUserNotManagerModal(castedBoolean);
        }
        return this._showUserNotManagerModal;
    };
    UiElementPreferencesService.ngInjectableDef = i0.defineInjectable({ factory: function UiElementPreferencesService_Factory() { return new UiElementPreferencesService(); }, token: UiElementPreferencesService, providedIn: "root" });
    return UiElementPreferencesService;
}());
export { UiElementPreferencesService };
