import { Injectable } from '@angular/core';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './services/auth.service';
import { TokenService } from './services/tokens.service';

@Injectable({ providedIn: 'root' })
export class AppResolver implements Resolve<any> {
  constructor(
    private readonly router: Router,
    private readonly tokenService: TokenService,
    private readonly auth: AuthService
  ) {}

  async resolve(route: ActivatedRouteSnapshot, rstate: RouterStateSnapshot): Promise<any> {
    await this.tokenService.checkTokens();

    if (this.tokenService.isTokenExpired) {
      this.router.navigate(['/login']);
    }

    return Promise.resolve();
  }
}
