<div mat-dialog-title>{{data.title}}</div>
<div mat-dialog-content>
  <table class="table table-bordered table-hover table-sm">
    <thead>
      <tr>
        <th class="font-weight-bold">User ID</th>
        <th class="font-weight-bold">First Name</th>
        <th class="font-weight-bold">Last Name</th>
        <th class="font-weight-bold">Email</th>
        <th class="font-weight-bold">Bill Rate</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let row of data.rows">
        <td class="font-weight-normal">
          <a routerLink="/admin/users/{{row.userID}}" routerLinkActive="active" (click)="closeDialog()">{{row.userID}}</a>
        </td>
        <td>{{row.firstName}}</td>
        <td>{{row.lastName}}</td>
        <td>{{row.email}}</td>
        <td>{{row.billRate}}</td>
      </tr>
    </tbody>
  </table>
</div>

<div mat-dialog-actions>
  <button uiKitButton mat-dialog-close>Close</button>
</div>
