<h1 mat-dialog-title>There's an issue</h1>

<div mat-dialog-content>
  <p>{{ data.redirectMessage }}</p>
</div>

<div mat-dialog-actions class="mat-dialog-actions">
  <button 
    uiKitButton
    mat-dialog-close>OK</button>
</div>
