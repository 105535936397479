/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../common/ui-kit/button/button.ngfactory";
import * as i3 from "../../common/ui-kit/button/button";
import * as i4 from "./redirect-error.dialog";
var styles_RedirectErrorDialog = [];
var RenderType_RedirectErrorDialog = i0.ɵcrt({ encapsulation: 2, styles: styles_RedirectErrorDialog, data: {} });
export { RenderType_RedirectErrorDialog as RenderType_RedirectErrorDialog };
export function View_RedirectErrorDialog_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(-1, null, ["There's an issue"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 5, "div", [["class", "mat-dialog-actions mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i0.ɵdid(8, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 3, "button", [["mat-dialog-close", ""], ["type", "button"], ["uiKitButton", ""]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11).dialogRef.close(i0.ɵnov(_v, 11).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_UiKitButtonDirective_0, i2.RenderType_UiKitButtonDirective)), i0.ɵdid(10, 1622016, null, 0, i3.UiKitButtonDirective, [i0.ElementRef], { uiKitButtonColor: [0, "uiKitButtonColor"] }, null), i0.ɵdid(11, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), (_l()(), i0.ɵted(-1, 0, ["OK"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_3 = ""; _ck(_v, 10, 0, currVal_3); var currVal_4 = ""; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.redirectMessage; _ck(_v, 6, 0, currVal_1); var currVal_2 = (i0.ɵnov(_v, 11).ariaLabel || null); _ck(_v, 9, 0, currVal_2); }); }
export function View_RedirectErrorDialog_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_RedirectErrorDialog_0, RenderType_RedirectErrorDialog)), i0.ɵdid(1, 49152, null, 0, i4.RedirectErrorDialog, [i1.MAT_DIALOG_DATA], null, null)], null, null); }
var RedirectErrorDialogNgFactory = i0.ɵccf("ng-component", i4.RedirectErrorDialog, View_RedirectErrorDialog_Host_0, {}, {}, []);
export { RedirectErrorDialogNgFactory as RedirectErrorDialogNgFactory };
