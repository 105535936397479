/**
 * Web client constants. We don't need to explicitly set types here because TypeScript
 * will implicitly set types for us.
 *
 * See https://www.typescriptlang.org/docs/handbook/type-inference.html
 */

export const DEFAULT_SNACKBAR_ACTION = 'OK';
export const DEFAULT_SNACKBAR_DURATION = 4000;

export const DEFAULT_MAP_LAT = 36.575474;
export const DEFAULT_MAP_LNG = -119.908026;
export const MAP_DIALOG_WIDTH = '400px';

export const MOMENT_SHORT_DATE_FORMAT = 'MMMM Do YYYY';
export const MOMENT_SHORT_TIME_FORMAT = 'h:mm';
export const MOMENT_SHORT_DATETIME_FORMAT = 'MMMM Do YYYY, h:mm';
export const NG_SHORT_DATE_FORMAT = 'MMM d, y';
export const NG_SHORT_DATETIME_FORMAT = 'MMMM d, y, h:mm a';
export const SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const VAL_NOT_AVAILABLE_PLACEHOLDER = '---';

const storageKeyPrefix = 'apz_';
const storageKeyVersion = '2';
const buildKey = (name: string): string => storageKeyPrefix + name + storageKeyVersion;

export const APP_FEATURE = {
  FILTER_CROPS_COUNTIES: 'Map:FilterCropCounties',
  WATER_DISTRICTS: 'Map:WaterDistrictDataSet',
  CA_CROP_2020: 'Map:CaCrop2020DataSet',
  CA_CROP_2021: 'Map:CaCrop2021DataSet',
  CA_CROP_2022: 'Map:CaCrop2022DataSet',
  WA_CROP_2020: 'Map:WaCrop2020DataSet',
  CA_CROP_CHANGE_2019_2020: 'Map:CaCropChange_2019_2020',
  CA_CROP_CHANGE_2020_2021: 'Map:CaCropChange_2020_2021',
  PARCELS: 'Map:Parcels',
  KEYS_SEARCH: 'Search:Keys'
};

export const STORAGE_KEY = {
  USER_NOT_MANAGER: buildKey('unmsmk'),
  AUTH_TOKEN: buildKey('t'),
  AUTH_EXPIRES: buildKey('e'),
  AUTH_REFRESH: buildKey('r'),
  AUTH_USER: buildKey('u'),
  AUTH_ROLE: buildKey('re'),
  AUTH_DEVICE_TOKEN: buildKey('deviceToken'),
  MAP_FILTER_SETTINGS: buildKey('mFilter')
};

export const FORM_FIELD = {
  EMAIL: {
    MAX_LENGTH: 50
  }
};
