import { ErrorHandler } from '@angular/core';
import { Routes } from '@angular/router';
import { NotGrowerAccountGuard } from './services/auth-guard.service';
import { AppResolver } from './app.resolver';
import WorkspaceConfig from '../workspace-config';
import { version } from '../../package.json';
import { environment } from '../environments/environment';
// import { Integrations as ApmIntegrations } from '@sentry/apm';
import * as Sentry from '@sentry/browser';
var routes = [
    { path: '', loadChildren: './splash/splash.module#SplashModule', pathMatch: 'full' },
    { path: 'about', loadChildren: './about-us/about-us.module#AboutUsModule' },
    {
        path: 'contact',
        loadChildren: './contact-us/contact-us.module#ContactUsModule'
    },
    {
        path: 'signup',
        loadChildren: './signup/signup.module#SignupModule'
    },
    {
        path: 'reset-password',
        loadChildren: './reset-password/reset-password.module#ResetPasswordModule'
    },
    {
        path: 'dash',
        loadChildren: './dash/dash.module#DashModule',
        resolve: {
            canResolve: AppResolver
        }
    },
    {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule',
        resolve: {
            canResolve: AppResolver
        }
    },
    {
        path: 'map',
        loadChildren: './mapview/mapview.module#MapviewModule',
        canActivate: [NotGrowerAccountGuard],
        resolve: {
            canResolve: AppResolver
        }
    },
    { path: 'search', loadChildren: './search/search.module#SearchModule' },
    { path: 'privacy', loadChildren: './privacy/privacy.module#PrivacyModule' },
    { path: 'terms', loadChildren: './terms/terms.module#TermsModule' },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'logout', loadChildren: './logout/logout.module#LogoutModule' },
    {
        path: 'unsubscribe/:key',
        loadChildren: './unsubscribe/unsubscribe.module#UnsubscribeModule'
    },
    { path: '**', redirectTo: '' }
];
Sentry.init({
    dsn: 'https://b3eaa0950c0d4d4b8676cdc568c2289b@sentry.io/1189213',
    enabled: environment.production ? true : false,
    release: 'agproz-front@' + version
    // integrations: [new ApmIntegrations.Tracing()],
    // tracesSampleRate: 0.25
});
var SentryErrorHandler = /** @class */ (function () {
    function SentryErrorHandler() {
    }
    SentryErrorHandler.prototype.handleError = function (error) {
        Sentry.captureException(error.originalError || error);
        Sentry.configureScope(function (scope) {
            scope.setTag('version_number', version);
        });
        throw error;
    };
    return SentryErrorHandler;
}());
export { SentryErrorHandler };
var ɵ0 = WorkspaceConfig, ɵ1 = {
    duration: 10000,
    horizontalPosition: 'center',
    verticalPosition: 'bottom'
}, ɵ2 = { width: '350px', closeOnNavigation: true, disableClose: false, hasBackdrop: true };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2 };
