import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

import { JWTService } from './jwt.service';

import { version } from '../../../package.json';

@Injectable({
  providedIn: 'root'
})
export class BuildOptionsService {
  constructor(private readonly jwt: JWTService) {}

  buildOptions(): HttpHeaders {
    return new HttpHeaders({
      Accept: 'application/json',
      Authorization: 'Bearer ' + this.jwt.checkAuthToken(),
      Client: 'web',
      'Device-Token': this.jwt.checkDeviceToken(),
      'Version-Number': version
    });
  }
}
