import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material';

@Component({
  selector: 'app-admin-billing-dashboard-dialog',
  templateUrl: './admin-billing-dashboard-dialog.component.html'
})
export class AdminBillingDashboardDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public readonly data: DashboardDialogData, private readonly dialog: MatDialog) {}

  public closeDialog() {
    this.dialog.closeAll();
  }
}

export interface DashboardDialogData {
  title: string;
  rows: any[];
}
