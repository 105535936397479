import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CanActivate } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly auth: AuthService) {}

  /**
   * Determines what features with which a given user can interact
   * @return {boolean}
   */
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.loggedIn()) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }
  }
}

@Injectable()
export class AdminAuthGuard implements CanActivate {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  /**
   * Determines what features with which a given user can interact
   * @return {boolean}
   */
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.auth.loggedIn()) {
      this.router.navigate(['/login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }

    if (this.auth.getUser().role < 4) {
      this.router.navigate(['/dash'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }

    return true;
  }
}

@Injectable()
export class NotGrowerAccountGuard implements CanActivate {
  constructor(private readonly auth: AuthService, private readonly router: Router) {}

  /**
   * Determines what features with which a given user can interact
   * @return {boolean}
   */
  canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.auth.getUser() && this.auth.getUser().userType !== 2) {
      return true;
    } else {
      this.router.navigate(['/login'], {
        queryParams: {
          redirectUrl: state.url
        },
        queryParamsHandling: 'merge'
      });

      return false;
    }
  }
}
