import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { APP_FEATURE } from '../common/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./auth.service";
var UserHasGeoSearchSubscriptionGuard = /** @class */ (function () {
    function UserHasGeoSearchSubscriptionGuard(router, auth) {
        this.router = router;
        this.auth = auth;
    }
    /**
     * Determines if the user has access to the Geo Search subscription
     * @return {boolean}
     */
    UserHasGeoSearchSubscriptionGuard.prototype.canActivate = function () {
        if (this.auth.loggedIn() && this.auth.getUser().subscriptions.includes(APP_FEATURE.KEYS_SEARCH)) {
            return true;
        }
        else {
            this.router.navigate(['/']);
            return false;
        }
    };
    UserHasGeoSearchSubscriptionGuard.ngInjectableDef = i0.defineInjectable({ factory: function UserHasGeoSearchSubscriptionGuard_Factory() { return new UserHasGeoSearchSubscriptionGuard(i0.inject(i1.Router), i0.inject(i2.AuthService)); }, token: UserHasGeoSearchSubscriptionGuard, providedIn: "root" });
    return UserHasGeoSearchSubscriptionGuard;
}());
export { UserHasGeoSearchSubscriptionGuard };
