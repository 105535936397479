import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { RestService } from '../services/rest.service';

import { Contact } from '../interfaces/contact.interface';
import { Member } from '../interfaces/member.interface';
import { Team } from '../interfaces/team.interface';
import { UserCustomer } from '../interfaces/user-customer.interface';

import { environment } from '../../environments/environment';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashTeamsService {
  constructor(private readonly rest: RestService, public readonly auth: AuthService, private readonly router: Router) {}

  public readonly teamSubject: BehaviorSubject<Team> = new BehaviorSubject({});
  public readonly contactsSubject: BehaviorSubject<Contact[]> = new BehaviorSubject([]);
  public readonly userCustomerSubject: BehaviorSubject<UserCustomer> = new BehaviorSubject({});
  public readonly loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public readonly holdingSubject: BehaviorSubject<any[]> = new BehaviorSubject([]);
  public readonly viewTeamMemberAcres: BehaviorSubject<{
    userID: number;
    acres: any[];
    name: string;
  }> = new BehaviorSubject(null);

  getTeam(year: number = 2020): Promise<any> {
    if (this.auth.loggedIn()) {
      const promise = this.rest.get(`${environment.apiURL}/team?year=${year}`);

      promise.then(res => {
        this.teamSubject.next(res);
        this.loadingSubject.next(false);
      });

      return promise;
    } else {
      return Promise.resolve();
    }
  }

  getManagedTeams(): Promise<void> {
    if (this.auth.loggedIn()) {
      return this.rest.get(`${environment.apiURL}/user/managed-teams`).then(res => {
        this.auth.setUserManagedTeams(res.data);
      });
    } else {
      return Promise.resolve();
    }
  }

  removeTeamInvite(teamID: number, email: string): Promise<any> {
    return this.rest.post(`${environment.apiURL}/team/${teamID}/removeInvite`, { email });
  }

  createNewTeam(team): Promise<any> {
    return this.rest.post(`${environment.apiURL}/team`, team);
  }

  editTeamMember(team: Team, editedMember: Member): void {
    const members: Member[] = team.members.map(member => {
      if (member.id === editedMember.id) {
        member.email = editedMember.email;
        member.fname = editedMember.fname;
        member.isAdmin = editedMember.isAdmin;
        member.lname = editedMember.lname;
      }

      return member;
    });

    this.teamSubject.next({
      ...team,
      members: members
    });

    this.rest.put(`${environment.apiURL}/team/edit`, editedMember).then(() => {
      this.getTeam();
    });
  }

  transferTeamMemberInfo(fromTeamMemberID: number, toTeamMemberID: number, teamID: number): Promise<void> {
    return this.rest.put(`${environment.apiURL}/user/${fromTeamMemberID}/moveto/${toTeamMemberID}/team/${teamID}`, {});
  }

  suspendTeamMember(teamID: number, teamMemberID: number): Promise<void> {
    return this.rest.delete(`${environment.apiURL}/team/${teamID}/member/${teamMemberID}`);
  }

  moveTeamMemberToEscrow(team: Team, teamMemberID: number): Promise<void> {
    // Apply local changes
    const members = team.members.map(el => {
      if (el.id === teamMemberID) {
        // Reassign values to what the server will do anyway
        el.fname = `Data from ${el.fname}`;

        // Update member holding
        el.holding = 1;
      }

      return el;
    });

    this.teamSubject.next({
      ...team,
      members: members
    });

    return this.rest.delete(`${environment.apiURL}/team/${team.id}/member/${teamMemberID}/remove`);
  }

  deleteTeamMember(teamMemberID: number): Promise<void> {
    return this.rest.delete(`${environment.apiURL}/user/${teamMemberID}`);
  }

  reinstateTeamMember(teamID: number, teamMemberID: number): Promise<void> {
    return this.rest.put(`${environment.apiURL}/team/${teamID}/member/${teamMemberID}`, {});
  }

  toggleTeamMemberAdmin(team: Team, teamMemberID: number, isAdmin: number): Promise<void> {
    const teamID = team.id;

    const members: Member[] = team.members.map(member => {
      if (member.id === teamMemberID) {
        member.isAdmin = isAdmin;
      }

      return member;
    });

    this.teamSubject.next({
      ...team,
      members: members
    });

    return this.rest.put(`${environment.apiURL}/team/${teamID}/makeAdmin/${teamMemberID}/${isAdmin}`, {});
  }

  sendTeamMemberInvite(teamID: number, email: string): Promise<any> {
    return this.rest.post(`${environment.apiURL}/team/${teamID}/invite`, {
      email: email
    });
  }

  deactivateUserAccount(): Promise<void> {
    return this.rest.post(`${environment.apiURL}/user/deactivate`, {});
  }

  reactivateUserAccount(): Promise<void> {
    return this.rest.post(`${environment.apiURL}/user/reactivate`, {});
  }

  toggleSharedTeamData(teamID: number, typeID: number): Promise<void> {
    return this.rest.put(`${environment.apiURL}/team/${teamID}/type/${typeID}`, {});
  }

  toggleTeamBilling(teamID: number): Promise<void> {
    return this.rest.put(`${environment.apiURL}/team/${teamID}/billing`, {});
  }

  getContacts(): Promise<void> {
    if (this.auth.loggedIn()) {
      const promise = this.rest.get(`${environment.apiURL}/contact`);

      promise.then(res => {
        this.contactsSubject.next(res);
      });

      return promise;
    } else {
      return Promise.resolve();
    }
  }

  getUserCustomerInfo(): Promise<void> {
    if (this.auth.loggedIn()) {
      const promise = this.rest.get(`${environment.apiURL}/user/info`);

      promise.then(res => {
        this.userCustomerSubject.next(res);

        if (res && res.status === 0) {
          this.router.navigate(['/dash/financials/deactivated']);
        } else if (!res) {
          console.warn('getUserCustomerInfo is', typeof res);
        }
      });

      return promise;
    } else {
      return Promise.resolve();
    }
  }
}
