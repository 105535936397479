import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

import { AuthService } from './services/auth.service';
import { RestService } from './services/rest.service';

import { filter, map, mergeMap } from 'rxjs/operators';

interface MetaEventItem {
  name: string;
  content: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    public readonly auth: AuthService,
    private readonly router: Router,
    private readonly title: Title,
    private readonly route: ActivatedRoute,
    private readonly meta: Meta,
    private readonly rest: RestService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);

    this.router.events
      .pipe(
        // Handle this event only on the end of the navigation call stack
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        // Traverse over the state tree and find the last activated route,
        // and return it to the stream
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        // Handle this event only on the primary outlet
        filter(route => route.outlet === 'primary'),
        // Dive into the children property of the routes config and fetch the
        // corresponding page meta
        mergeMap(route => route.data)
      )

      // Subscribe to stream
      .subscribe(event => {
        window.scrollTo(0, 0);

        this.title.setTitle(event['title']);

        if (event['meta'] && typeof event['meta'] === 'object') {
          event['meta'].map(metaItem => {
            this.meta.updateTag({
              name: metaItem.name,
              content: metaItem.content
            });
          });
        }
      });

    // Fetch coordinates for the mapview upon immediately initialization of the app
    if ('geolocation' in navigator) {
      // ...fetch the geolocation
      return new Promise(() => {
        // Continue to fetch the coordinates in the background for the next time
        navigator.geolocation.getCurrentPosition(position => {
          // Create the latlng object
          const latlng = {
            lat: position.coords.latitude,
            lng: position.coords.longitude
          };

          if (!this.auth.browserSecurityError) {
            // Cache the coordinates and expires time for the coordinates
            localStorage.setItem('latlng', JSON.stringify(latlng));
            localStorage.setItem('latlngexpires', String(Math.floor(new Date().getTime() / 1000) + 180));
          }
        });
      });
    }
  }
}
