import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';

import { AuthService } from './auth.service';

import { APP_FEATURE } from '../common/constants';

@Injectable({
  providedIn: 'root'
})
export class UserHasGeoSearchSubscriptionGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService) {}

  /**
   * Determines if the user has access to the Geo Search subscription
   * @return {boolean}
   */
  canActivate(): boolean {
    if (this.auth.loggedIn() && this.auth.getUser().subscriptions.includes(APP_FEATURE.KEYS_SEARCH)) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
