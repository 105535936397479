import { BaseEnvironment } from './base';
import { Environment } from './typings';

export const environment: Environment = {
  ...BaseEnvironment,
  apiURL: 'https://devapi.agproz.com',
  mapsKey: 'AIzaSyAVQdh4KbwFIGz_wx2Xq5dA-N5-xDBfs-w',
  production: true,
  hmr: false,
  CAPTCHA_KEY: '6LeREoYkAAAAANmH4CbGOolpEsa2yIAQztbgQL4m',
};
