import { Injectable } from '@angular/core';

import { client as braintreeClient, hostedFields as braintreeHostedFields } from 'braintree-web';

@Injectable()
export class BraintreeService {
  /**
   * A Promise-based wrapper for requesting a payment method nonce from Braintree
   * @return {Promise<any>}
   */
  requestPaymentMethod(instance: any): Promise<any> {
    return new Promise((resolve, reject) => {
      instance.tokenize((error, payload) => {
        if (error) {
          return reject(error);
        } else {
          return resolve(payload);
        }
      });
    });
  }

  initializeHostedFields(tokenizationKey: string): Promise<any> {
    return new Promise((resolve, reject) => {
      braintreeClient.create(
        {
          authorization: tokenizationKey,
          container: '#dropin-container'
        },
        (err, clientInstance) => {
          if (err) {
            reject(err);
          }

          braintreeHostedFields.create(
            {
              client: clientInstance,
              styles: {
                input: {
                  color: '#333',
                  'font-size': '15px'
                }
              },
              fields: {
                number: {
                  selector: '#card-number',
                  placeholder: '1111 1111 1111 1111'
                },
                cvv: {
                  selector: '#cvv',
                  placeholder: 'CVV'
                },
                expirationDate: {
                  selector: '#expiration-date',
                  placeholder: 'MM/YY'
                }
              }
            },
            (err, hostedFieldsInstance) => {
              if (err) {
                reject(err);
              }

              resolve(hostedFieldsInstance);
            }
          );
        }
      );
    });
  }
}
