/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./button.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./button";
var styles_UiKitButtonDirective = [i0.styles];
var RenderType_UiKitButtonDirective = i1.ɵcrt({ encapsulation: 0, styles: styles_UiKitButtonDirective, data: {} });
export { RenderType_UiKitButtonDirective as RenderType_UiKitButtonDirective };
export function View_UiKitButtonDirective_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["id", "wrapper"]], null, null, null, null, null)), i1.ɵncd(null, 0)], null, null); }
export function View_UiKitButtonDirective_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["ui-kit-button", ""]], null, null, null, View_UiKitButtonDirective_0, RenderType_UiKitButtonDirective)), i1.ɵdid(1, 1622016, null, 0, i2.UiKitButtonDirective, [i1.ElementRef], null, null)], null, null); }
var UiKitButtonDirectiveNgFactory = i1.ɵccf("[ui-kit-button], [uiKitButton]", i2.UiKitButtonDirective, View_UiKitButtonDirective_Host_0, { uiKitButtonColor: "uiKitButton", loading: "loading", disabled: "disabled" }, {}, ["*"]);
export { UiKitButtonDirectiveNgFactory as UiKitButtonDirectiveNgFactory };
