var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { BaseEnvironment } from './base';
export var environment = __assign({}, BaseEnvironment, { apiURL: 'https://devapi.agproz.com', mapsKey: 'AIzaSyAVQdh4KbwFIGz_wx2Xq5dA-N5-xDBfs-w', production: true, hmr: false, CAPTCHA_KEY: '6LeREoYkAAAAANmH4CbGOolpEsa2yIAQztbgQL4m' });
