import { Injectable } from '@angular/core';

import { STORAGE_KEY } from '../common/constants';

@Injectable({
  providedIn: 'root'
})
export class UiElementPreferencesService {
  protected readonly userNotManagerModalKey: string = STORAGE_KEY.USER_NOT_MANAGER;

  private _showUserNotManagerModal: boolean = true;

  constructor() {}

  setShowUserNotManagerModal(showModal: boolean): void {
    localStorage.setItem(this.userNotManagerModalKey, showModal.toString());
    this._showUserNotManagerModal = showModal;
  }

  showUserNotManagerModal(): boolean {
    if (localStorage.getItem(this.userNotManagerModalKey)) {
      const castedBoolean: boolean = localStorage.getItem(this.userNotManagerModalKey) === 'true' ? true : false;
      this.setShowUserNotManagerModal(castedBoolean);
    }

    return this._showUserNotManagerModal;
  }
}
