import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { STORAGE_KEY } from '../common/constants';

export interface AuthUser {
  fname: string;
  id: number;
  lname: string;
  managedTeams: any[];
  role: 1 | 2 | 3 | 4 | 5;
  subscriptions: string[];
  userType: 1 | 2 | 3;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public token: string = '';
  public deviceToken: string = '';
  public role: number = -1;
  public refresh: string = '';
  public expires: number = -1;
  public user: any = null;
  public browserSecurityError: boolean = false;

  protected readonly tokenKey: string = STORAGE_KEY.AUTH_TOKEN;
  protected readonly expiresKey: string = STORAGE_KEY.AUTH_EXPIRES;
  protected readonly refreshKey: string = STORAGE_KEY.AUTH_REFRESH;
  protected readonly userKey: string = STORAGE_KEY.AUTH_USER;
  protected readonly roleKey: string = STORAGE_KEY.AUTH_ROLE;
  protected readonly deviceTokenKey: string = STORAGE_KEY.AUTH_DEVICE_TOKEN;

  constructor(
    private readonly router: Router,
    private readonly matDialog: MatDialog
  ) {}

  /**
   * @description Utility validator that checks if a user has required state
   * @returns {boolean}
   */
  loggedIn(): boolean {
    if (!this.browserSecurityError) {
      if (this.getAuthToken() && this.getAuthToken() !== '' && this.getDeviceToken() && this.getDeviceToken() !== '') {
        return true;
      } else {
        return false;
      }
    }
  }

  /**
   * Utility helper that logs a user into this service's state
   */
  login(expiryTime: number, refreshToken: string, jwt: string, deviceToken: string, userInfo: AuthUser): void {
    this.setExpires(expiryTime);
    this.setRefresh(refreshToken);
    this.setAuthToken(jwt);
    this.setDeviceToken(deviceToken);
    this.setUser(userInfo);
  }

  setAuthToken(token: string): void {
    localStorage.setItem(this.tokenKey, token);
    this.token = token;
  }

  setRole(role: number): void {
    localStorage.setItem(this.roleKey, String(role));
    this.role = role;
  }

  getAuthToken(): string {
    try {
      if (localStorage.getItem(this.tokenKey) && localStorage.getItem(this.tokenKey) !== '') {
        this.setAuthToken(localStorage.getItem(this.tokenKey));
      } else {
        return '';
      }
    } catch (e) {
      this.browserSecurityError = true;
    }

    return localStorage.getItem(this.tokenKey);
  }

  setRefresh(refresh: string): void {
    localStorage.setItem(this.refreshKey, refresh);
    this.refresh = refresh;
  }

  getRefresh(): string {
    if (!this.browserSecurityError) {
      if (localStorage.getItem(this.refreshKey) && localStorage.getItem(this.refreshKey) !== '') {
        this.setRefresh(localStorage.getItem(this.refreshKey));
      }
    }

    return this.refresh;
  }

  setExpires(expires: number): void {
    localStorage.setItem(this.expiresKey, String(expires));
    this.expires = expires;
  }

  getExpires(): number {
    if (!this.browserSecurityError) {
      if (localStorage.getItem(this.expiresKey) && localStorage.getItem(this.expiresKey) !== '') {
        this.setExpires(Number(localStorage.getItem(this.expiresKey)));
      }
    }

    return this.expires;
  }

  setUser(user: AuthUser): void {
    localStorage.setItem(this.userKey, JSON.stringify(user));
    this.user = user;
  }

  getUser(): AuthUser {
    if (!this.browserSecurityError) {
      if (localStorage.getItem(this.userKey)) {
        this.user = JSON.parse(localStorage.getItem(this.userKey));
      }
    }
    return this.user;
  }

  public hasFeature(feature: string): boolean {
    return this.getUser().subscriptions.includes(feature);
  }

  setDeviceToken(deviceToken: string): void {
    localStorage.setItem(this.deviceTokenKey, deviceToken);
    this.deviceToken = deviceToken;
  }

  getDeviceToken(): string {
    if (!this.browserSecurityError) {
      if (localStorage.getItem(this.deviceTokenKey)) {
        this.deviceToken = localStorage.getItem(this.deviceTokenKey);
      }
    }
    return this.deviceToken;
  }

  /**
   * Destroys the local session
   */
  logout() {
    this.matDialog.closeAll();

    this.setAuthToken(null);
    this.setRefresh(null);
    this.setExpires(null);
    this.setUser(null);
    this.setRole(null);

    localStorage.removeItem(this.tokenKey);
    localStorage.removeItem(this.refreshKey);
    localStorage.removeItem(this.expiresKey);
    localStorage.removeItem(this.userKey);
    localStorage.removeItem(this.roleKey);
    localStorage.removeItem(STORAGE_KEY.MAP_FILTER_SETTINGS);
  }

  setUserManagedTeams(teams: number[]): void {
    const user = this.getUser();

    user.managedTeams = teams;

    this.setUser(user);
  }
}
