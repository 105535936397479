import { NgModule, ErrorHandler, NO_ERRORS_SCHEMA, Injectable } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import {
  GestureConfig,
  MatSnackBarModule,
  MatIconModule,
  MatDialogModule,
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material';

import { AppComponent } from './app.component';
import { RedirectErrorDialog } from './services/redirect-error/redirect-error.dialog';
import { AlertService, AlertComponent } from './common/alert/alert';
import { UiKitModule } from './common/ui-kit';

import { AuthService } from './services/auth.service';
import { AuthGuard, AdminAuthGuard, NotGrowerAccountGuard } from './services/auth-guard.service';
import { BraintreeService } from './services/braintree.service';
import { BuildOptionsService } from './services/build-options.service';
import { DashTeamsService } from './services/dash-teams.service';
import { ExcelService } from './services/excel.service';
import { JWTService } from './services/jwt.service';
import { RestService } from './services/rest.service';
import { UiElementPreferencesService } from './services/ui-element-preferences.service';
import { UserHasGeoSearchSubscriptionGuard } from './services/subscription-guard.service';
import { TokenService } from './services/tokens.service';

import { AppResolver } from './app.resolver';

import WorkspaceConfig from '../workspace-config';
import { version } from '../../package.json';
import { environment } from '../environments/environment';

// import { Integrations as ApmIntegrations } from '@sentry/apm';
import * as Sentry from '@sentry/browser';
import { AdminBillingDashboardDialog } from './admin/admin-billing-dashboard/admin-billing-dashboard-dialog.component';

const routes: Routes = [
  { path: '', loadChildren: './splash/splash.module#SplashModule', pathMatch: 'full' },
  { path: 'about', loadChildren: './about-us/about-us.module#AboutUsModule' },
  {
    path: 'contact',
    loadChildren: './contact-us/contact-us.module#ContactUsModule'
  },
  {
    path: 'signup',
    loadChildren: './signup/signup.module#SignupModule'
  },
  {
    path: 'reset-password',
    loadChildren: './reset-password/reset-password.module#ResetPasswordModule'
  },
  {
    path: 'dash',
    loadChildren: './dash/dash.module#DashModule',
    resolve: {
      canResolve: AppResolver
    }
  },
  {
    path: 'admin',
    loadChildren: './admin/admin.module#AdminModule',
    resolve: {
      canResolve: AppResolver
    }
  },
  {
    path: 'map',
    loadChildren: './mapview/mapview.module#MapviewModule',
    canActivate: [NotGrowerAccountGuard],
    resolve: {
      canResolve: AppResolver
    }
  },
  { path: 'search', loadChildren: './search/search.module#SearchModule' },
  { path: 'privacy', loadChildren: './privacy/privacy.module#PrivacyModule' },
  { path: 'terms', loadChildren: './terms/terms.module#TermsModule' },
  { path: 'login', loadChildren: './login/login.module#LoginModule' },
  { path: 'logout', loadChildren: './logout/logout.module#LogoutModule' },
  {
    path: 'unsubscribe/:key',
    loadChildren: './unsubscribe/unsubscribe.module#UnsubscribeModule'
  },
  { path: '**', redirectTo: '' }
];

Sentry.init({
  dsn: 'https://b3eaa0950c0d4d4b8676cdc568c2289b@sentry.io/1189213',
  enabled: environment.production ? true : false,
  release: 'agproz-front@' + version
  // integrations: [new ApmIntegrations.Tracing()],
  // tracesSampleRate: 0.25
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    Sentry.configureScope(scope => {
      scope.setTag('version_number', version);
    });
    throw error;
  }
}

@NgModule({
  declarations: [AppComponent, AlertComponent, RedirectErrorDialog, AdminBillingDashboardDialog],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, { enableTracing: false }),
    UiKitModule,
    MatSnackBarModule,
    MatDialogModule,
    MatIconModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
    {
      provide: 'RestServiceOpts',
      useValue: WorkspaceConfig
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 10000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom'
      }
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { width: '350px', closeOnNavigation: true, disableClose: false, hasBackdrop: true }
    },
    BuildOptionsService,
    RestService,
    AuthService,
    AuthGuard,
    AdminAuthGuard,
    BraintreeService,
    NotGrowerAccountGuard,
    JWTService,
    UserHasGeoSearchSubscriptionGuard,
    DashTeamsService,
    UiElementPreferencesService,
    AlertService,
    ExcelService,
    TokenService
  ],
  entryComponents: [AlertComponent, RedirectErrorDialog, AdminBillingDashboardDialog],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
