<div class="wrapper">
  <div class="row">
    <div class="col message-col">
      <p>{{ data.message }}</p>
    </div>

    <div class="col action-col">
      <mat-icon (click)="handleClose()">close</mat-icon>
    </div>
  </div>
</div>
