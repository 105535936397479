import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';

@Component({
  templateUrl: './redirect-error.dialog.html'
})
export class RedirectErrorDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public readonly data: {
      redirectMessage: string;
      redirectUrl?: string;
    }
  ) {}
}
